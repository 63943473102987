/* Reset some default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set a base font size and family */
body {
  font-family: "Poppins", serif;
  font-size: 16px;
  line-height: 1.5;
  background-color: #100500 !important;
  color: #333;
}

/* Container for centering content */
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Basic styling for headers */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
  font-weight: 600;
}

/* Basic styling for paragraphs */
p {
  margin-bottom: 1rem;
}

/* Basic styling for links */
a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Basic button styling */
button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
}

button:hover {
  background: linear-gradient(90deg, #E81717 -15.32%, #E69C25 100%);
  color: white;
}

.form-control:focus {
  border-color: #fece86 !important;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(253, 157, 13, 0.25) !important;
}

.form-select:focus {
  border-color: #fece86 !important;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(253, 157, 13, 0.25) !important;
}

.mno>input:focus {
  box-shadow: none !important;
}

.contact-us-img {
  width: auto;
  height: 250px;
}

select {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
}

.offcanvas-backdrop.show {
  opacity: 1 !important;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}


.fs-28 {
  font-size: 28px;
}

.fs-30 {
  font-size: 30px;
}

.custom-modal .modal-dialog {
  min-width: 800px;
  /* Default width */
  width: 90%;
  /* Responsive */
}

@media (max-width: 768px) {
  .custom-modal .modal-dialog {
    max-width: 90%;
    /* Small screens pe width adjust karega */
  }

  .contact-us-img {
    width: auto;
    height: 100px;
  }
}

.colortheme {
  color: rgba(234, 85, 54, 0.7)
}

.modal-body {
  padding: 0px !important;
}

.custom-facebook-btn {
  background-color: rgba(234, 85, 54, 0.7);
  /* Facebook Blue */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.custom-facebook-btn:hover {
  background-color: #1558b0;
  /* Darker shade on hover */
}

.custom-facebook-btn:active {
  transform: scale(0.95);
  /* Press effect */
}

/* HTML: <div class="loader"></div> */
.custom-loader {
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
  0% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background-color: rgb(234, 84, 54);
  }

  33% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background-color: rgba(234, 84, 54, 0.399);
  }

  66% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background-color: rgba(234, 85, 54, 0.7);
  }

  100% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background-color: rgb(234, 84, 54);
  }
}


@media screen and (min-device-width: 200px) and (max-device-width: 360px) {
  .referral-tab1.nav.nav-tabs li {
    width: 100%;
  }

  .referral-tab1.nav.nav-tabs li:nth-child(1) {
    margin-bottom: 10px;
  }
}

/* ===== */
.price_tag {
  /* font-size: 13px;
  color: white;
  border: 1px solid #ffffff50;
  width: fit-content;
  padding: 5px;
  border-radius: 4px; */

  background: linear-gradient(270deg, #05d6d900 -4%, #683d07b8) border-box !important;
  font-size: 18px;


}

.price_tag p {
  background: var(--Linner, linear-gradient(90deg, #E81717 -15.32%, #E59C25 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}


/* =============== user guid steps css start =============== */
.step_container .steps_tick {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  position: relative;
}

.step_container .steps_tick span {
  content: '';
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgb(232 23 23 / 42%) -15.32%, rgb(229 156 37 / 57%) 100%);
  width: 40px;
  border-radius: 50%;
  height: 40px;
  position: relative;
  z-index: 2;
  margin-left: 10px;
}

.step_container .steps_tick::after {
  content: '';
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgb(205 68 68) -15.32%, rgb(122 98 57) 100%);
  width: 50px;
  border-radius: 50%;
  height: 50px;
  position: absolute;
  z-index: 0;
  top: -4px;
  left: 1px;
  filter: blur(10px);
}

.steps_tick::before {
  content: '';
  display: block;
  width: 70%;
  height: 2px;
  /* background: gray; */
  position: absolute;
  top: 50%;
  right: 25px;
  border: 1px dashed #2c2c2c;
}

.step_container ul li,
.step_container p {
  font-size: 14px;
}

.box_shadow>div {
  height: 100%;
  box-shadow: 0px 8px 9px rgb(24 20 15);
  border-radius: 10px;
}

/* =============== user guid steps css end =============== */

.home_page_pricetag {
  position: absolute;
  top: 60px;
  right: -90px;
  transform: translate(-50%, -50%);
  animation: upDown 4s infinite ease-in-out;
}

.price_tag_home {
  background: linear-gradient(90deg, rgb(169 20 20) -15.32%, rgb(169 116 31) 100%);
  width: auto;
  border-radius: 2px;
}

.price_tag_home p {
  font-size: 13px;
  color: white;
  text-shadow: 0px 2px 2px #100500;
}

@keyframes upDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0);
  }
}

@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
  .home_page_pricetag {
    position: absolute;
    top: 60px;
    right: -30px;
    transform: translate(-50%, -50%);
    animation: upDown 4s infinite ease-in-out;
  }

  .price_tag_home p {
    font-size: 10px;
  }

  .price_tag_home {
    width: auto;
  }
}