.btn01 {
  background: linear-gradient(90deg, #E81717 -15.32%, #E69C25 100%);
  box-shadow: 0px 0px 4px rgba(234, 85, 54, 0.7);
  border-radius: 40px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn02 {
  background: linear-gradient(90deg, #E81717 -15.32%, #E69C25 100%);
  box-shadow: 0px 0px 10px rgba(234, 85, 54, 0.7);
  border-radius: 10px;
  color: white;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn01,
.btn02 {
  position: relative;
  z-index: 10;
  overflow: hidden;
}

.btn01:hover::after,
.btn02:hover::after {
  width: 100%;
}

.btn01::after,
.btn02::after {
  content: '';
  position: absolute;
  top: 0px;
  width: 0%;
  height: 100%;
  /* background: red; */
  background: linear-gradient(90deg, #E59C25 -15.32%, #E81717 100%);
  z-index: -1;
  transition: .5s;
}

.header-text {
  background: linear-gradient(90deg, #E81717 -15.32%, #E59C25 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-color {
  color: #EA5536;
}



.text-background {
  background: linear-gradient(90deg, #E81717 -15.32%, #E59C25 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header {
  position: sticky;
  top: 0px;
  z-index: 111;
  background: #281e1a !important;
}

.navbar-background {
  /* background: #ffffff1a !important; */
  background: #3636361a !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: white;
}


.border-gradient-wrap {

  padding: 1px 1px;
  position: relative;
  background: linear-gradient(90deg, #E81717 -15.32%, #E59C25 100%);

  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-gradient {
  background: #222;
  color: white;
  border-radius: 40px;
  /* padding: 2rem; */
  padding: 5px 30px;
}

.fw-800 {
  font-weight: 800;
}

.fs-26 {
  text-align: center;
  font-size: 26px;
  font-weight: 600;
}

.modal-backdrop.show {
  background-color: rgba(0, 0, 0, 0.678) !important;
  backdrop-filter: blur(3px) !important;
  --bs-backdrop-opacity: none !important;
}



.custom-modal .modal-content {
  border-radius: 8px;
  background-color: red;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
}


@media (max-width:1200px) {
  .offcanvas-body01 {
    background-color: #100500 !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: white;
  }

  .lg-sp {
    margin-top: 20px;
  }
}

/* @media(max-width:768px) {
  .motag {
    display: none;
  }
} */

.navbar-toggler-icon {
  background-image: url(../../public/images/app.png) !important;
}

.btn-close {
  background-image: url(../../public/images/close2.png) !important;
  background-repeat: no-repeat !important;
  background-size: 100%;
}

.bshadow {
  box-shadow: #281E1A 4px 0px 10px 1px;
}

.referral-tab1.nav-tabs {
  --bs-nav-tabs-link-active-bg: transparent !important;
  --bs-nav-tabs-link-active-border-color: :none !important;
  --bs-nav-tabs-border-width: 0px !important;

}

.referral-tab1.nav-tabs>li {
  margin-right: 10px;

}

.referral-tab1>li>button {
  display: block;
  position: relative;
  /* padding: 1.3rem; */
  padding: 10px 30px;
  width: 100%;
  color: white;
  height: 100%;
  font-size: 12px;
  /* min-height: 400px; */
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.referral-tab1>li>button::before {
  content: "";
  position: absolute;
  cursor: pointer;
  inset: 0;

  border-radius: 50px;
  border: 2px solid transparent;
  background: linear-gradient(90deg, #E81717 -19.76%, #E59C25 100%) border-box;
  -webkit-mask: linear-gradient(90deg, #E81717 -19.76%, #E59C25 100%) border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.referral-tab1>li>button:hover {
  border-radius: 50px !important;
  color: white;
  background: linear-gradient(90deg, #e8171756 -19.76%, #e59b2552 100%) border-box;
}

.referral-tab1>li>button.active {
  border-radius: 50px !important;
  color: white !important;
  background: linear-gradient(90deg, #e8171771 -19.76%, #e59b256c 100%) border-box;
}