.footer {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.24);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.171) -306%, #100500 94%);
}

.smicons>.footer-page,
.smicons a {
    color: white;
}

.smicons .footer-page:hover,
.smicons a:hover {
    fill: #EA5536;
    color: #EA5536;
    cursor: pointer;
}

.footer-letter {
    display: block;
    position: relative;
    padding: 1.3rem;
    width: 100%;
    height: fit-content;
    font-size: 12px;
    /* min-height: 400px; */
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.footer-letter::before {
    content: "";
    position: absolute;
    cursor: pointer;
    inset: 0;

    border-radius: 10px;
    border: 2px solid transparent;
    background: linear-gradient(90deg, #e817178e -19.76%, #e59b2581 100%) border-box;
    -webkit-mask: linear-gradient(90deg, #E81717 -19.76%, #E59C25 100%) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
}

.smedia p {
    border-radius: 35px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.3)
}

.smedia p:hover {
    border-radius: 35px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--Linner, linear-gradient(90deg, #E81717 -15.32%, #E59C25 100%));
}

.footer-btn {
    background: linear-gradient(90deg, #E81717 -15.32%, #E59C25 100%);
    position: relative;
    z-index: 10;
    overflow: hidden;
}

.footer-btn:hover::after {
    width: 100%;
}

.footer-btn::after {
    content: '';
    position: absolute;
    top: 0px;
    width: 0%;
    height: 100%;
    /* background: red; */
    background: linear-gradient(90deg, #E59C25 -15.32%, #E81717 100%);
    z-index: -1;
    transition: .5s;
}

@media (max-width:600px) {
    .smicons {
        flex-wrap: wrap;
        justify-content: center;
        gap: 5px;
    }
}


.terms-heading {
    font-size: 20px;
    font-weight: 500;
}

.terms-para {
    color: #ffffffa1;
    font-weight: 400;
}

.terms-ol li {
    margin-bottom: 20px;
}

.contact-us-tab {
    border-radius: 10px;
    box-shadow: rgba(255, 255, 255, 0.301) 0px 1px 4px;
}

.contact-us-input::placeholder {
    font-size: 14px;
}

span.country-name {
    color: #100500;
}