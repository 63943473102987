.section1 {
    width: 100%;
    height: 100%;

}

.section1_container {
    border-radius: 50px;
    height: 100%;
    background: radial-gradient(288.53% 132.92% at 50% 18.33%, rgba(0, 0, 0, 0.00) 21.67%, rgba(234, 85, 54, 0.33) 53.02%, #EA5536 100%);
}

.section1-header {
    margin: 40px 0px;
}

.influencer {
    border: 1px dashed #EA5536;
    border-radius: 20px;
}

.header-01 {
    color: #FFF;
    text-align: center;
    font-size: 38px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.header-02 {
    color: #EA5536;
    /* text-align: center; */
    font-size: 38px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.subheader {
    width: 50%;
    color: white;
    text-align: center;
    font-size: 14px;
}

.main-timer {
    border-radius: 13.357px;
    border: 1.113px solid rgba(255, 255, 255, 0.20);
    background: rgba(255, 255, 255, 0.05);
}

.timer-data {
    color: #D7D7D7;
    text-align: center;
    font-size: 35.617px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0px;
    margin-top: 6px;
}

.timer-label {
    color: #A0A0A0;
    text-align: center;
    font-size: 13.357px;
    font-weight: 400;
    margin-bottom: 5px;
}

.timer-div {
    border-right: 2px solid transparent;
    /* Ensure there’s a border to apply the image to */
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 10%, #ffffff 47.5%, rgba(255, 255, 255, 0) 70%);

    border-image-slice: 1;
    /* Define how the border image should be sliced */


}

.timer-div:nth-last-child(1) {
    border-right: 0px solid transparent;


}

.learn-more {
    display: block;
    position: relative;
    padding: 1.3rem;
    width: 100%;
    height: 100%;
    font-size: 12px;
    min-height: 400px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.learn-more::before {
    content: "";
    position: absolute;
    cursor: pointer;
    inset: 0;

    border-radius: 50px;
    border: 2px solid transparent;
    background: linear-gradient(90deg, #E81717 -19.76%, #E59C25 100%) border-box;
    -webkit-mask: linear-gradient(90deg, #E81717 -19.76%, #E59C25 100%) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
}



.form-check-input:checked {
    background-color: #EA5536 !important;
    border-color: #ffffff !important;
}

.form-check-input:focus {
    box-shadow: none !important;
    border: none;
}

/* SECTION2  */

.section2 {
    width: 100%;
    height: 100%;
    background: radial-gradient(288.53% 132.92% at 50% 18.33%, rgba(0, 0, 0, 0.00) 21.67%, rgba(234, 85, 54, 0.33) 53.02%, #EA5536 100%);


}

.s2header-02 {
    color: #EA5536;
    /* text-align: center; */
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.section2-text {
    color: #FFF;
    font-size: 36px;
    font-weight: 500;
    line-height: 122.212%;
    /* 43.996px */
}

/* SECTION 3  */
.why-choose {
    font-size: 18px;
    font-weight: 600;
}

.section3-header {
    color: #EFEFEF;
    font-size: 40px;
    font-weight: 700;
}

.s3card {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-radius: 12px;
    border: 0.5px solid rgba(245, 245, 245, 0.267);
    background: linear-gradient(90deg, rgba(232, 23, 23, 0.10) -15.32%, rgba(229, 156, 37, 0.10) 100%);
}

.s3card-header {
    color: #EA5536;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
}

/* SECTION 4  */

.section4 {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 46.11%, rgba(234, 85, 54, 0.33) 97.53%, #EA5536 174.6%);
}

.section4-background {
    border-radius: 12px;
    border: 0.5px solid var(--Linner, #E81717);
    background: linear-gradient(90deg, rgba(232, 23, 23, 0.10) -15.32%, rgba(229, 156, 37, 0.10) 100%);
}

.pie {
    height: 500px;
    width: 500px;
    border-radius: 50%;
    background-color: #EEF0FA10;
}

.pie.hollow {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.pie.hollow::after {
    content: '';
    position: absolute;
    height: 300px;
    width: 300px;
    border-radius: 50%;
    background-color: transparent;
}

.s4 {
    width: 100%;
    height: 100%;
    border-radius: 16.273px;
}

.section4right {
    border-radius: 16.273px !important;
    width: 100%;
    height: 100%;
    background: rgba(160, 160, 160, 0.10);
    border: 2px solid transparent;
    /* Ensure there’s a border to apply the image to */
    border-image: linear-gradient(90deg, #E81717 -19.76%, #E59C25 100%);

    border-image-slice: 1;
    /* Define how the border image should be sliced */


}



.gradient-border-mask {
    display: block;
    position: relative;
    padding: 1.3rem;
    width: 100%;
    height: 100%;
    min-height: 400px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.gradient-border-mask::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 12px;
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: transparent;
    background: linear-gradient(90deg, #e81717b0 -19.76%, #E59C25 100%) border-box;
    -webkit-mask: linear-gradient(90deg, #E81717 -19.76%, #E59C25 100%) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    pointer-events: none;
    /* Allow clicks to pass through */
}


/* SECTION 5  */

.roadmap-icon {
    width: 80px;
    height: 80px;
    border-radius: 12px;
    background: linear-gradient(90deg, #E81717 -15.32%, #E59C25 100%);
}

.s5card {
    mix-blend-mode: luminosity;
    user-select: none;
    cursor: pointer;
    /* Make it clear that the cards are clickable */
}

.s5card.active {
    mix-blend-mode: normal;
    color: white;
    /* You can also add other styles for the active state here, like a hover effect */
}


.s5card:active {
    color: white;
    mix-blend-mode: normal;
    /* This is optional if you want to highlight when a card is being clicked */
}

.s5card .s5c .roadmap-detail {
    color: rgba(255, 255, 255, 0.329);
    font-size: 14px;
}

.s5card.active .s5c .roadmap-detail {
    color: rgb(255, 255, 255);
}

.s5overflow {
    overflow-x: scroll !important;
    max-width: 100vw;
    /* Ensures it doesn't exceed viewport */
    width: 100%;
}

.scroll-container {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    padding: 10px;
    width: 100%;
    max-width: 100vw;
    white-space: nowrap;
    scrollbar-width: thin;
    scrollbar-color: gray lightgray;
}

.scroll-item {
    width: 300px;
    height: 300px;
    background: lightblue;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    border: 2px solid blue;
    flex-shrink: 0;
    /* Prevent shrinking */
}


.s5overflow::-webkit-scrollbar {

    background: transparent;
}

.s5overflow::-webkit-scrollbar-thumb {
    border: 5px solid #100500;
    border-radius: 10px;
    background: linear-gradient(90deg, #E81717 -15.32%, #E59C25 100%);
}

.dashed-div {
    width: 100%;
    max-width: 100%;
    height: 1px;
    mix-blend-mode: luminosity;
    border: 1px dashed red;
    top: 40px;
    z-index: -100;
}

.custom-pie {
    width: 320px;
    height: 320px;
    margin-top: -10px;
}

.s1txt {
    text-align: center;
}

@media (max-width:1200px) {
    .s2header-02 {
        font-size: 30px;
    }

    .section2-text {
        font-size: 30px;
    }
}

@media (max-width:768px) {
    .header-01 {
        font-size: 26px;
    }

    .header-02 {
        font-size: 28px;
    }

    .s2header-02 {
        font-size: 38px;
    }

    .subheader {
        width: 100%;
    }

    .timer-data {
        font-size: 25.617px;
    }

    .section2-text {
        font-size: 20px;
    }

    .timer-div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .timer-label {
        font-size: 12px;
    }
}

@media (max-width:425px) {
    .timer-data {
        font-size: 18.617px;
    }

    .section3-header {
        color: #EFEFEF;
        font-size: 30px;
        font-weight: 700;

    }

    .s3card-header {
        font-size: 20px;
    }

    .custom-pie {
        width: 200px;
        height: 200px;
    }

    .cp {
        width: 200px;
        height: 200px;
    }

    .s2header-02 {
        font-size: 26px;
    }

    p {
        font-size: 13px;
    }

    .gradient-border-mask {
        min-height: 360px;
        height: 100%;
    }
}

@media (max-width:375px) {
    .header-01 {
        font-size: 22px;
    }

    .header-02 {
        font-size: 20px;
    }

    .s2header-02 {
        font-size: 26px;
    }

    .subheader {
        width: 100%;
    }

    .section2-text {
        font-size: 16px;
    }

    p {
        font-size: 12px;
    }
}


.s2container {
    padding-bottom: 0px !important;
}

/*---------------------------------- ICO LANDING PAGE ------------------ */

.ico-header {
    color: #EFEFEF;
    font-size: 58px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0px;
}

.ico-para {
    color: #D7D7D7;
    font-size: 16px;
    font-weight: 400;
}

.token-sale {
    color: #EFEFEF;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}

.ico-timer-data {
    color: #D7D7D7;
    font-size: 45.6px;
    font-weight: 600;
}

.ico-timer-label {
    color: #EFEFEF;
    font-size: 22px;
    font-weight: 600;
    text-transform: lowercase;
}

.ico-table-data {
    color: #EFEFEF;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
}

.ico-data {
    border-bottom: 1px solid rgba(255, 255, 255, 0.123);
}

.progress-bar {
    border-radius: 50px !important;
    background: linear-gradient(90deg, #E81717 -15.32%, #E69C25 100%) !important;
    color: white;
}

.pb {
    height: 15px !important;
    border-radius: 50px !important;
    background-color: rgba(160, 160, 160, 0.20) !important;
}

.cw-icon {
    width: 65px;
    height: 65px;
    border-radius: 10px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(255, 102, 0, 0.568);
}

.cw-icon img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.cw-p {
    font-size: 16px;
    margin-top: 10px;
    font-weight: 500;
    color: white;
}

.mod-cross {
    width: fit-content;
    border-radius: 3.652px;
    background: linear-gradient(90deg, #E81717 -15.32%, #E59C25 100%);
}

@media (max-width:768px) {
    .ico-header {
        color: #EFEFEF;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 0px;
    }

    .ico-timer-data {
        color: #D7D7D7;
        font-size: 25.6px;
        font-weight: 600;
    }

    .cw-icon {
        width: 45px;
        height: 45px;
    }

    .cw-icon img {
        width: 25px;
        height: 25px;
    }

    .cw-p {
        font-size: 12px;
    }
}

.login {
    width: 100%;
    height: 100%;

}

.modal-dialog {
    display: flex;
    justify-content: center;
}

.modal-content {
    border-radius: 12px;
    /* border: 0.5px solid var(--Linner, #E81717); */
    /* max-width: 600px; */
    width: 100%;
    background: linear-gradient(90deg, rgba(232, 23, 23, 0.10) -15.32%, rgba(229, 156, 37, 0.10) 100%) !important;
    backdrop-filter: blur(25px);
}

.modal-body {
    align-content: center;
}

.modal-content {
    display: block;
    position: relative;
    padding: 1.3rem;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.modal-content::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 12px;
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: transparent;
    background: linear-gradient(90deg, #e81717b0 -19.76%, #E59C25 100%) border-box;
    -webkit-mask: linear-gradient(90deg, #E81717 -19.76%, #E59C25 100%) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    pointer-events: none;
    /* Allow clicks to pass through */
}

.login-header {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    background: var(--Linner, linear-gradient(90deg, #E81717 -15.32%, #E59C25 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.timer-div>input {
    background-color: transparent !important;
    box-shadow: none;
    border: 1px solid rgba(255, 255, 255, 0.11);
    border-radius: 10px;
}

.timer-div>input:focus-visible {
    outline: none;
}

.timer-div-last>input {
    background-color: transparent !important;
    box-shadow: none;
    border: 1px solid rgba(255, 255, 255, 0.11);
    border-radius: 10px;
}

.timer-div-last>input:focus-visible {
    outline: none;
}

.login-code {
    font-size: 26px;
    text-align: center;
    font-weight: 600;
}

@media (max-width:1200px) {
    .login-header {}
}

@media (max-width:768px) {
    .login-header {
        font-size: 20px;
    }

    .login-code {
        font-size: 16px;
    }
}

@media (max-width:425px) {
    .login-header {
        font-size: 20px;
    }
}

.form-control::placeholder {
    font-size: 14px;
}

/* ======= image css by dev ======== */
.three_h_img {
    /* max-height: 300px !important;
    min-height: 300px; */
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.react-tel-input .flag-dropdown {
    height: 41px !important;
    top: 0px !important;
}

.react-tel-input input {
    font-size: 16px !important;
}


#s3feature,
#s4tokenomics,
#s5roadmap {
    scroll-margin-top: 50px;
}

a.text-white.nav-link {
    position: relative;
}

a.text-white.nav-link::before {
    content: '';
    position: absolute;
    background: #d57f13;
    height: 2px;
    width: 0%;
    left: 0px;
    bottom: 0px;
    transition: 0.3s;
}

a.text-white.nav-link.active::before {
    content: '';
    position: absolute;
    background: #d57f13;
    height: 2px;
    width: 100%;
    left: 0px;
    bottom: 0px;
    transition: 0.3s;
}

a.text-white.nav-link:hover::before {
    content: '';
    position: absolute;
    background: #d57f13;
    height: 2px;
    width: 100%;
    left: 0px;
    bottom: 0px;
}

/* ======================== */
.width_content {
    width: calc(100% - 240px);
}

@media screen and (min-device-width: 200px) and (max-device-width: 992px) {
    .width_content {
        width: 100%;
    }
}