.d-icon {
  border-radius: 12px;
  border: 0.5px solid var(--Orange, #EA5536);
  background: rgba(160, 160, 160, 0.10);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
}

.dashboard-card {
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  gap: 20px;
  background: linear-gradient(90deg, rgba(232, 23, 23, 0.10) -15.32%, rgba(229, 156, 37, 0.10) 100%);
}

.rdt_TableHeadRow {
  border-radius: 12px;
  /* border: 1px solid white !important; */
  /* background-color: rgba(160, 160, 160, 0.10) !important; */
  background: linear-gradient(90deg, rgb(137 32 32) -15.32%, rgb(118 83 26 / 97%) 100%);

}

.rdt_TableHead {
  padding: 10px;
  border-radius: 10px;

}

.rdt_TableHeadRow {
  display: block;
  position: relative;
  padding: 1.3rem;
  width: 100%;
  height: 100%;
  /* min-height: 400px; */
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

/* .rdt_TableHeadRow::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 12px;
  border-width: 0px;
  border-style: solid;
  border-color: transparent;
  background: linear-gradient(90deg, rgba(232, 23, 23, 0.37) -15.32%, rgba(229, 155, 37, 0.479) 100%);
  backdrop-filter: blur(25px);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
} */

.rdt_TableBody {
  padding: 0px 30px;
}

.dash-gradient2 {
  display: block;
  position: relative;
  padding: 1.3rem;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.dash-gradient2::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 12px;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: transparent;
  background: linear-gradient(90deg, #e81717b0 -19.76%, #E59C25 100%) border-box;
  -webkit-mask: linear-gradient(90deg, #E81717 -19.76%, #E59C25 100%) border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  pointer-events: none;
  /* Allow clicks to pass through */
}

.buy-now__control {
  cursor: pointer !important;
}

.buy-now__menu {
  cursor: pointer !important;
}

.buy-now__option {
  cursor: pointer !important;
}

.disabled-input {
  background-color: #c7c7c7 !important;
  /* Light gray background */
  color: #454545d0 !important;
  /* Light gray text */
  font-style: italic;
  /* Italic text */
  cursor: not-allowed;
  border: 1px solid #dcdcdc !important;
  /* Light border */
}


.profile-container {
  border-radius: 10px;
  background: linear-gradient(90deg, rgba(232, 23, 23, 0.10) -15.32%, rgba(229, 156, 37, 0.10) 100%);
  backdrop-filter: blur(25px);
}

.profile-update-wrap {

  padding: 3px 3px;
  position: relative;
  background: linear-gradient(90deg, #E81717 -15.32%, #E59C25 100%);

  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-update {
  background: #222;
  border-radius: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 2rem; */
  padding: 8px 30px;
}

.dash-header {
  color: #FFF;
  font-size: 22px;
  font-weight: 500;
}

.wallet-card {
  max-width: 543px;
  width: 100%;
  /* min-height: 200px; */
  height: 100%;
  border-radius: 10px;
  background-image: url('../../public/images/wallet.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wallet-card-data p {
  font-size: 20px;
}

.dh-card-data p {
  font-size: 12px;
}

.noti {
  border-bottom: 1px solid #ffffff15;
}

.noti:nth-last-child(1) {
  border-bottom: none;
}

.pd-col {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
}

/* .pie-detail {

display: flex;
justify-content: center;
align-items: center;
text-align: center;
  } */

.refer {
  background-image: url('../../public/images/refer.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  border-radius: 18px;
}

.refer-btn {

  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.refer-btn022 {

  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width:500px) {
  .refer-btn {
    width: 30px;
    height: 30px;
    border-radius: 4px;
  }

  .refer-btn022 {
    width: 30px;
    height: 30px;
    border-radius: 4px;
  }

  .raf p {
    font-size: 12px;
    font-weight: 400;
  }
}

.wrapper-dash {
  /* height: 100vh; */
  overflow-y: scroll;

}

.wrapper-dash::-webkit-scrollbar {
  display: none;
}

/* .wrapper {
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: transparent;
    position: relative; }
   

      .content-wrapper {
        min-height: 100%;
        background-color: #f1f5f8;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        /* border-radius: 1.25rem; */
/* margin-right: 0.75rem; } */


.status-label {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  display: inline-block;
}

.status-label.pending {
  background-color: #ff9800;
  /* Orange */
}

.status-label.success {
  background-color: #4caf50;
  /* Green */
}

.status-label.reject {
  background-color: #f44336;
  /* Red */
}

.status-label.unknown {
  background-color: #9e9e9e;
  /* Grey */
}


.noti-number {
  background-color: #E59C25;
  color: white;
  text-decoration: none !important;
  font-size: 10px;
  padding: 5px;
  border-radius: 30px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width 0.3s ease-in-out, padding 0.3s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.noti-number .full-text {
  display: none;
  transition: opacity 0.2s ease-in-out;
  margin-left: 5px;
}

.noti-number:hover {
  width: 120px;
  /* Expanding effect */
  padding: 5px 10px;
}

.noti-number:hover .full-text {
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  /* Show text smoothly */
}


/* .noti-number:after {
  content: "notification";
  width: 100px !important;
} */