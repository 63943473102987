.sidebar-tab:hover {
  text-decoration: none;
  backdrop-filter: blur(25px);
  background: linear-gradient(90deg, rgba(232, 23, 23, 0.10) -15.32%, rgba(229, 156, 37, 0.10) 100%);

}

.sidebar-tab {

  border-radius: 12px 0px 0px 12px;
  background: rgba(239, 239, 239, 0.10);
  text-decoration: none;
  color: white;
  mix-blend-mode: luminosity;
}


.sidebar-tab.active {
  border-radius: 12px 0px 0px 12px;
  color: #EA5536;
  background: linear-gradient(90deg, rgba(232, 23, 23, 0.10) -15.32%, rgba(229, 156, 37, 0.10) 100%);
  backdrop-filter: blur(25px);
  mix-blend-mode: normal;
}

.slide-animation {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.offcanvas-start {
  transform: translateX(-100%);
  opacity: 0;
}

.offcanvas.show {
  transform: translateX(0);
  opacity: 1;
}


.ocb {
  padding-right: 0px !important;
}

.dash-gradient {
  display: block;
  position: relative;
  padding: 1.3rem;
  width: 100%;
  height: 100%;
  /* min-height: 400px; */
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.dash-gradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 12px 0px 0px 12px;
  border-right: none;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: #ffffff50;
  background: linear-gradient(90deg, #e81717b0 -19.76%, #E59C25 100%) border-box;
  -webkit-mask: linear-gradient(90deg, #E81717 -19.76%, #E59C25 100%) border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.sidebar-gradient {
  display: block;
  position: relative;
  padding: 1.3rem;
  width: 100%;
  height: 100%;
  /* min-height: 400px; */
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.sidebar-gradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 12px 0px 0px 12px;
  border-right: none;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: #ffffff50;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}


/* .sdbar-large{
  position: fixed;
    width: 19.29rem;
    padding-bottom: 0;
    /* height: calc(100% - 80px);  */
/* max-height: 90%;
    height: 100vh;
    overflow: auto;
    bottom: 0; */
/* }  */